'use client';

import { AppAction } from '@/contexts/TokenContext/Roles';
import { useIsAllowed } from '@/contexts/TokenContext/useIsAllowed';
import { PropsWithChildren } from 'react';

export interface RestrictedContentProps extends PropsWithChildren {
    action?: AppAction;
    fallback?: React.ReactNode;
}

export default function RestrictedContent(props: RestrictedContentProps): React.ReactNode {
    const action = props.action;
    const allowed = useIsAllowed(action);

    // Allowed isn't defined yet
    if (allowed === undefined) {
        return null;
    }

    if (!allowed) {
        let fallback = props.fallback;
        if (fallback === undefined) {
            fallback = null;
        }
        return fallback;
    }

    return props.children;
}
